import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import {
  resolveCategoryUrl,
  resolveProductUrl,
  resolveShortProductUrl,
} from 'eshop-defaults/lib/utilities/selectors';
import { __, __r } from 'react-i18n';
import { cartRoutes } from '../Cart/utilities';
import { resolveShortCategoryUrl } from '../Category/selectors';

export enum BreadCrumbType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  MY_ACCOUNT_ORDERS = 'MY_ACCOUNT_ORDERS',
  MY_ACCOUNT_REQUESTS = 'MY_ACCOUNT_REQUESTS',
  MY_ACCOUNT_REQUESTS_DETAIL = 'MY_ACCOUNT_REQUESTS_DETAIL',
  MY_ACCOUNT_INFO = 'MY_ACCOUNT_INFO',
  MY_ACCOUNT_DELIVERY_ADDRESSES = 'MY_ACCOUNT_DELIVERY_ADDRESSES',
  ORDER_RETURNS = 'ORDER_RETURNS',
  MY_ACCOUNT_ACCOUNTS = 'MY_ACCOUNT_ACCOUNTS',
  MY_ACCOUNT_CHANGE_PASSWORD = 'MY_ACCOUNT_CHANGE_PASSWORD',
  MY_ACCOUNT_OREDER_DETAIL = 'MY_ACCOUNT_OREDER_DETAIL',
  CONTACT = 'CONTACT',
  CMS_ARTICLES = 'CMS_ARTICLES',
  CMS = 'CMS',
  NONE = 'NONE',
  CART = 'CART',
  CUSTOM = 'CUSTOM',
  CMS_GENERAL = 'CMS_GENERAL',
}

export type SingleBreadCrumb = { name: string; url: string };
export type BreadCrumbPath = SingleBreadCrumb[];

interface BreadCrumbState {
  path: BreadCrumbPath;
}

const initialState: BreadCrumbState = {
  path: [],
};

const breadCrumbSlice = createSlice({
  name: 'breadCrumb',
  initialState,
  reducers: {
    setBreadCrumb(
      state,
      action: PayloadAction<{
        path: BreadCrumbPath;
      }>,
    ) {
      state.path = action.payload.path;
    },
  },
});

export const { setBreadCrumb } = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;

const breadCrumbDomainSelector = state => state.breadCrumb;

export const breadCrumbSelector = createSelector(
  breadCrumbDomainSelector,
  substate => {
    return prop(substate, 'path', null);
  },
);

export const setBreadCrumbPath = (
  type: BreadCrumbType,
  data?: any,
  additionalData?: any,
): any => async (dispatch, getState) => {
  const path: BreadCrumbPath = [];
  switch (type) {
    case BreadCrumbType.CATEGORY: {
      prop(data, 'parent_categories', []).map(parentCat => {
        path.push(createBreadCrumbFromCategory(parentCat));
      });
      path.push(createBreadCrumbFromCategory(data));
      break;
    }

    case BreadCrumbType.PRODUCT: {
      const currentCategory = prop(additionalData, 'currentCategory');
      let parentCategoryIds: number[] = [];

      let parentCategories: any[] = [];
      if (currentCategory !== null) {
        parentCategories = getCurrentParentCategories(
          data,
          currentCategory,
        ).reverse();
      } else {
        parentCategories = prop(data, 'parent_categories', []);
      }

      parentCategories.some(parentCat => {
        if (currentCategory !== null) {
          path.push(createBreadCrumbFromCategory(parentCat));
          return (
            currentCategory !== null &&
            parseInt(currentCategory, 10) === parentCat.category_id
          );
        } else {
          const parentExists = parentCategoryIds.includes(
            parentCat.category_parent,
          );
          parentCategoryIds.push(parentCat.category_parent);
          if (!parentExists) {
            path.push(createBreadCrumbFromCategory(parentCat));
          }
          return parentExists;
        }
      });
      path.push(createBreadCrumbFromProduct(data));
      break;
    }

    case BreadCrumbType.LOGIN: {
      path.push({
        name: __('Prihlásenie'),
        url: __r('routes:prihlasenie', '/prihlasenie'),
      });
      break;
    }
    case BreadCrumbType.REGISTRATION: {
      path.push({
        name: __('Registrácia'),
        url: __r('routes:registracia', '/registracia'),
      });
      break;
    }
    case BreadCrumbType.FORGOT_PASSWORD: {
      path.push({
        name: __('Zabudnuté heslo'),
        url: __r('routes:zabudnute-heslo', '/zabudnute-heslo'),
      });
      break;
    }

    case BreadCrumbType.FORGOT_PASSWORD: {
      path.push({
        name: __('Zabudnuté heslo'),
        url: __r('routes:zabudnute-heslo', '/zabudnute-heslo'),
      });
      break;
    }

    case BreadCrumbType.MY_ACCOUNT_ORDERS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje objednávky a faktúry'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_REQUESTS:
    case BreadCrumbType.MY_ACCOUNT_REQUESTS_DETAIL: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje dopyty'),
        url: __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_INFO: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje údaje'),
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_OREDER_DETAIL: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje objednávky a faktúry'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      path.push({
        name: __('Detail objednávky'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
      });
      break;
    }

    case BreadCrumbType.ORDER_RETURNS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      const orderPublicId = prop(additionalData, 'orderPublicId');
      const step = prop(additionalData, 'step');
      path.push({
        name: __(`Vrátenie tovaru`),
        url: `${__r(
          `routes:moj-ucet/vratenie-tovaru`,
          '/moj-ucet/vratenie-tovaru/',
        )}/${step}/${orderPublicId}`,
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_DELIVERY_ADDRESSES: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Moje adresy'),
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_ACCOUNTS: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Správa účtov'),
        url: __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
      });
      break;
    }
    case BreadCrumbType.MY_ACCOUNT_CHANGE_PASSWORD: {
      path.push({
        name: __('Môj účet'),
        url: __r('routes:moj-ucet', '/moj-ucet'),
      });
      path.push({
        name: __('Zmena hesla'),
        url: __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
      });
      break;
    }

    case BreadCrumbType.CONTACT: {
      path.push({
        name: __('Kontakt'),
        url: __r('routes:kontakt', '/kontakt'),
      });
      break;
    }

    case BreadCrumbType.CMS_ARTICLES: {
      path.push({
        name: __('Magazín'),
        url: __r('routes:magazin', '/magazin'),
      });
      break;
    }

    case BreadCrumbType.CMS: {
      path.push({
        name: __('Magazín'),
        url: __r('routes:magazin', '/magazin'),
      });
      path.push(createBreadCrumbFromCMSArticle(data));
      break;
    }

    case BreadCrumbType.CMS_GENERAL: {
      path.push(createBreadCrumbFromCMSArticle(data));
      break;
    }

    case BreadCrumbType.CART: {
      path.push({
        name: __('Nákupný košík'),
        url: prop(data, 'url', __r(cartRoutes.INDEX, '/kosik')),
      });
      break;
    }

    case BreadCrumbType.CUSTOM: {
      path.push({
        name: data.name,
        url: data.url,
      });
      break;
    }

    case BreadCrumbType.NONE: {
      break;
    }

    default:
      break;
  }
  dispatch(setBreadCrumb({ path }));
};

const getCurrentParentCategories = (
  product: any,
  currentCategoryId: number,
  result: any[] = [],
) => {
  const c = prop(product, 'parent_categories', []).find(
    parentCat => parentCat.category_id === currentCategoryId,
  );
  if (c) {
    result.push(c);
    if (c.category_parent && c.category_parent !== null) {
      getCurrentParentCategories(product, c.category_parent, result);
    }
  }

  return result;
};

const createBreadCrumbFromCategory = (category: any): SingleBreadCrumb => {
  const name =
    prop(category, 'publish.0.category_name') ||
    prop(category, 'category_name');
  const id = prop(category, 'category_id', '');
  const categoryUrl =
    prop(category, 'publish.0.url') || prop(category, 'url', 'url');
  const url = categoryUrl;

  return { name, url };
};

const createBreadCrumbFromProduct = (product: any): SingleBreadCrumb => {
  const name =
    prop(product, 'publish.0.product_name') || prop(product, 'name', '');
  const id = prop(product, 'product_id', '');
  const productUrl =
    prop(product, 'publish.0.product_url') || prop(product, 'url', 'url');
  const url = resolveProductUrl(id, productUrl);
  return { name, url };
};

const createBreadCrumbFromCMSArticle = (article: any): SingleBreadCrumb => {
  const name = prop(article, 'name', '');
  const url = `/${prop(article, 'url', 'url')}`;

  return { name, url };
};
