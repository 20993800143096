import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { LanguageProvider, __, __r } from 'react-i18n';
// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';
import UrlMapper from './containers/UrlMapper/UrlMapper';
import SearchResults from './containers/Search/SearchResults';
import ProductContainer from './containers/Product/Product';
import LoginPage from './containers/Auth/LoginPage';
import RegisterPage from './containers/Auth/RegisterPage';
import CMSArticles from './containers/CMS/CMSArticles';
import ForgotPassword from './containers/Auth/ForgotPassword';
import MyAccount from './containers/MyAccount/MyAccount';
import MyOrders from './containers/MyAccount/MyOrders';
import MyRequests from './containers/MyAccount/MyRequests';
import OrderDetail from './containers/MyAccount/OrderDetail';
import MyRequestsDetail from './containers/MyAccount/MyRequestsDetail';
import MyInfoContainer from './containers/MyAccount/MyInfo';
import MyDeliveryAddressesContainer from './containers/MyAccount/MyDeliveryAddresses';
import API from './services/API';
import Activation from './containers/MyAccount/Activation';
import NewPassword from './containers/MyAccount/NewPassword';
import ChangePassword from './containers/MyAccount/ChangePassword';
import Category from './containers/Category/Category';
import AccountManagementContainer from './containers/MyAccount/AccountManagement';
import { cartRoutes } from './utilities/cart';
import Cart from './containers/Cart/Cart';
import CartFirstStep from './containers/Cart/CartFirstStep';
import CartSecondStep from './containers/Cart/CartSecondStep';
import CartThirdStep from './containers/Cart/CartThirdStep';
import FinishedOrder from './containers/Cart/FinishedOrder';
import OrderReturns from './containers/MyAccount/OrderReturns';
import CartHlafstep from './containers/Cart/CartHlafstep';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const renderActivation = props => {
  return <Activation API={API} params={props.params} />;
};

const Routes = (props: any) => {
  return (
    <Router onUpdate={scrollToTop} history={props.history}>
      <Route path="/" component={App}>
        <Route
          path={__r('routes:vyhladavanie', '/vyhladavanie')}
          component={SearchResults}
        />
        <Route
          path={`${__r('routes:produkt', '/produkt')}/:product_id/:product_url`}
          component={ProductContainer}
        />
        {[
          `${__r('routes:kategoria', '/kategoria')}/:category_id/:category_url`,
        ].map((path, index) => (
          <Route path={path} component={Category} key={index} />
        ))}
        <Route
          path={__r('routes:prihlasenie', '/prihlasenie')}
          component={LoginPage}
        />
        <Route
          path={__r('routes:registracia', '/registracia')}
          component={RegisterPage}
        />
        <Route
          path={__r('routes:zabudnute-heslo', '/zabudnute-heslo')}
          component={ForgotPassword}
        />
        <Route path={__r('routes:moj-ucet', '/moj-ucet')} component={MyAccount}>
          <Route
            path={__r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky')}
            component={MyOrders}
          />
          <Route
            path={__r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty')}
            component={MyRequests}
          />
          <Route
            path={`${__r('routes:moj-ucet/dopyty', 'moj-ucet/dopyty')}/:id`}
            component={MyRequestsDetail}
          />
          <Route
            path={__r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje')}
            component={MyInfoContainer}
          />
          <Route
            path={__r(
              'routes:moj-ucet/moje-dodacie-adresy',
              '/moj-ucet/moje-dodacie-adresy',
            )}
            component={MyDeliveryAddressesContainer}
          />
          <Route
            path={__r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov')}
            component={AccountManagementContainer}
          />
          <Route
            path={__r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla')}
            component={ChangePassword}
          />
          <Route
            path={`${__r(
              'routes:moj-ucet/vratenie-tovaru',
              '/moj-ucet/vratenie-tovaru',
            )}/:step/:orderPublicId`}
            component={OrderReturns}
          />
          <Route
            path={`${__r(
              'routes:moj-ucet/objednavka',
              '/moj-ucet/objednavka',
            )}/:orderPublicId`}
            component={OrderDetail}
          />
          <IndexRoute component={MyOrders} />
        </Route>

        <Route path={__r(cartRoutes.INDEX, '/kosik')} component={Cart}>
          <Route
            path={__r(cartRoutes.SECOND_STEP, '/kosik/osobne-udaje')}
            component={CartSecondStep}
          />
          <Route
            path={__r(cartRoutes.THIRD_STEP, '/kosik/dodanie-a-platba')}
            component={CartThirdStep}
          />
          <Route
            path={__r(cartRoutes.HALF_STEP, '/kosik/prihlasenie')}
            component={CartHlafstep}
          />
          <IndexRoute component={CartFirstStep} />
        </Route>
        <Route
          path={`${__r(
            'routes:dokoncena-objednavka',
            '/dokoncena-objednavka',
          )}/:orderPublicId`}
          component={FinishedOrder}
        />

        <Route
          path={__r('routes:magazin', '/magazin')}
          component={CMSArticles}
        />
        <Route
          path={`${__r('routes:nove-heslo', '/nove-heslo/')}/:token`}
          component={NewPassword}
        />
        <Route path={'/activation/:code'} component={renderActivation} />
        <Route path="**" component={UrlMapper} />
        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
