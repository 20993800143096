import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import API, { ThenArg } from '../../services/API';
import { __, __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import {
  FinishedOrderReturn,
  H1,
  InfoText,
  LoaderWrapper,
  MetaTags,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import {
  fetchFinishedOrder,
  finishedOrderDataSelector,
  finishedOrderIsFetchingSelector,
  setStep,
  requestStep,
  requestOrderReturnIsFetching,
  customerInfoSelector,
} from './myAccountSlice';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import FullOrder from '../../components/MyAccount/FullOrder';
import { setOrderToReturn } from '../../containers/MyAccount/myAccountSlice';
import { prop } from '../../utilities';
import { replace } from 'react-router-redux';
import OrderDetail from '../../components/MyAccount/OrderDetail';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  padding: 0;
  background-color: white;
  margin: 0 auto;
  border-radius: ${rem(4)};
  ${({ theme }) => theme.mediab.l925`
   padding-top: ${rem(24)};
  `}
`;

interface Props {
  dispatch: any;
  orderData: ThenArg<typeof API.getOrder>;
  isFetching_order: boolean;
  user;
}

class OrderDetailContainer extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const {
      dispatch,
      params: { orderPublicId },
    } = props;

    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_OREDER_DETAIL));
      await Promise.all([dispatch(fetchFinishedOrder(orderPublicId))]);
      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const { orderData, isFetching_order, user } = this.props;

    if (isFetching_order) {
      return <LoaderWrapper />;
    }
    if (!orderData) {
      return (
        <InfoText
          type={InfoType.ERROR}
          info={__('Takáto objednávka neexistuje')}
        />
      );
    }

    return (
      <>
        <MetaTags tags={{ title: __('Objednávka | GOLF CENTRUM.sk') }} />{' '}
        <OrderDetail
          orderData={orderData}
          isFetching_order={isFetching_order}
          user={user}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderData: finishedOrderDataSelector(state),
    isFetching_order: finishedOrderIsFetchingSelector(state),
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrderDetailContainer' })(
    withRouter(OrderDetailContainer),
  ),
);
