import * as React from 'react';
import { __, __r } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { push } from 'react-router-redux';
import { authProtected } from '../../utilities/auth';
import { Sidebar, FlexRow, MetaTags, FlexCol } from 'eshop-defaults';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { withRouter, WithRouterProps } from 'react-router';
import { fetchCart } from '../Cart/cartSlice';
import { logoutUser } from '../MyAccount/actions';

interface Props {
  user: any;
  dispatch: any;
  children: any;
}

class MyAccount extends React.Component<Props & WithRouterProps> {
  public render() {
    const {
      user,
      children,
      location: { pathname },
    } = this.props;

    const isOrderDetailPage = pathname.includes(
      __r('routes:moj-ucet/objednavka', '/moj-ucet/objednavka'),
    );

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags tags={{ title: __('Moj účet') }} />
          {!isOrderDetailPage && this.renderSidebar()}
          <ContentWrapper>{user ? children : null}</ContentWrapper>
        </Wrapper>
      </>
    );
  }

  public renderSidebar = () => {
    const {
      location: { pathname },
    } = this.props;
    const items = [
      {
        name: __('Moje objednávky'),
        url: __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky'),
          ) || pathname === __r('routes:moj-ucet', '/moj-ucet'),
      },
      // {
      //   name: 'Moje dopyty',
      //   url: __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
      //   active: pathname.includes(
      //     __r('routes:moj-ucet/dopyty', '/moj-ucet/dopyty'),
      //   ),
      // },
      {
        name: __('Moje údaje'),
        url: __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
        active:
          pathname.includes(
            __r('routes:moj-ucet/moje-udaje', '/moj-ucet/moje-udaje'),
          ) ||
          pathname.includes(
            __r('routes:moj-ucet/zmena-hesla', '/moj-ucet/zmena-hesla'),
          ),
      },
      {
        name: __('Moje dodacie adresy'),
        url: __r(
          'routes:moj-ucet/moje-dodacie-adresy',
          '/moj-ucet/moje-dodacie-adresy',
        ),
        active: pathname.includes(
          __r(
            'routes:moj-ucet/moje-dodacie-adresy',
            '/moj-ucet/moje-dodacie-adresy',
          ),
        ),
      },
      // {
      //   name: 'Správa účtov',
      //   url: __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
      //   active: pathname.includes(
      //     __r('routes:moj-ucet/sprava-uctov', '/moj-ucet/sprava-uctov'),
      //   ),
      // },
    ];

    return <Sidebar handleLogout={this.logoutUser} items={items} />;
  };

  public logoutUser = () => {
    this.props.dispatch(logoutUser());
    // this.props.dispatch(push('/'));
    this.props.dispatch(fetchCart());
  };
}

const ContentWrapper = styled(FlexCol)`
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: ${rem(56)} ${rem(24)};
  justify-content: flex-start;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(16)} ${rem(32)};
  `}
`;

export const LogoutButton = styled.button`
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(90%);
  }

  margin: ${rem(56)} auto;
  flex: 0 1 auto;
  border: none;
  border-radius: 0 ${rem(2)} ${rem(2)} 0;
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.secondary};
  height: ${rem(48)};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  max-width: ${rem(320)};

  padding: ${rem(15)} ${rem(32)};
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

const MyAccountProtected = withRouter(authProtected(MyAccount));
// @TODO REMOVE - 161, 159 ponechat
// const MyAccountProtected = withRouter(MyAccount);
// const MyAccountProtected = MyAccount;

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyAccount' })(MyAccountProtected),
);
