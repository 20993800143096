import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import { FlexCol, FullOrderAccountInfo, FullOrder } from 'eshop-defaults';
import { IconType, SvgIcon } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';

interface Props {
  orderData: ThenArg<typeof API.getOrder>;
  isFetching_order: boolean;
  user;
}

function OrderDetail({ orderData, isFetching_order, user }: Props) {
  return (
    <Wrapper>
      <BackLink to={__r('routes:moj-ucet/objednavky', '/moj-ucet/objednavky')}>
        <SvgIconStyled
          icon={IconType.back}
          width={16}
          height={16}
          alt={'back icon'}
          marginRight={5}
          viewBox={'0 0 16 16'}
          fill={''}
        />{' '}
        <StyledText> {__('Späť na objednávky')}</StyledText>
      </BackLink>
      {/* user logged */}
      {user && <FullOrderAccountInfo order={orderData} />}
      {/* end user logged */}
      <FullOrder order={orderData} />
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const BackLink = styled(Link)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  margin-bottom: ${rem(36)};

  &:hover {
    text-decoration: underline;
  }
`;

const SvgIconStyled = styled(SvgIcon)`
  height: 100%;
  margin-top: ${rem(2)};
`;
const StyledText = styled.span`
  text-decoration: underline;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
`;

export default OrderDetail;
